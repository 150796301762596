import { Button, Stack, Typography } from "@mui/material";
import {
  INFO_EMAIL_OUTREACH,
  DEV_IMAGE,
} from "../../constants";
import { Column, Image, MediaStack, Row } from "../common";
import { LIGHT_SHADOW } from "../theme";
import { useHomeScrollContext } from "../../contexts";

const ASPECT_RATIO = 408 / 612;
const WIDTH = { mobile: 200, desktop: 400 };
export default function About() {
  const { aboutRef } = useHomeScrollContext();
  return (
    <Stack ref={aboutRef}>
      <MediaStack
        sxBelow={{ flexDirection: "column-reverse" }}
        sxAbove={{ alignItems: "flex-start" }}
        spacing={6}
      >
        <Image
          sx={{ boxShadow: LIGHT_SHADOW }}
          variant="fixed-width"
          mobileWidth="100%"
          src={DEV_IMAGE}
          width={`${WIDTH.desktop}px`}
          height={`${ASPECT_RATIO * WIDTH.desktop}px`}
        />
        <Column spacing={4}>
          <Description />
          <Button
            sx={{ width: "max-content" }}
            onClick={() => window.open(INFO_EMAIL_OUTREACH)}
          >
            Reach out
          </Button>
        </Column>
      </MediaStack>
    </Stack>
  );
}

const bullets = [
  "The fastest iteration on new ideas",
  "The most budget flexibility",
  "The best grasp of frontier technologies",
  "Over 1M lines of code written",
];

function Description() {
  return (
    <Column spacing={2}>
      <Typography variant="h1">
        The Best Development Firm for Startups
      </Typography>

      <Typography color="text.secondary">
        We are a world class development organization with a specialty on helping new companies or internal research teams go 0 to 1. We have experience across a variety of new technologies including Artificial Intelligence and Blockchain. 
      </Typography>

      <Column spacing={0.5}>
        <Typography variant="h4" color="text.primary">
          Standard was formed by former start up founders and investors
        </Typography>

        {bullets.map((b, i) => (
          <Row alignItems="flex-start" spacing={1} key={i}>
            <Typography color="text.disabled">•</Typography>
            <Typography color="text.secondary"> {b} </Typography>
          </Row>
        ))}
      </Column>
    </Column>
  );
}
