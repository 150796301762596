import { Stack, Typography } from "@mui/material";
import { LOGO_SQUARE } from "../../constants";
import { Column, Image } from "../common";
import { LIGHT_SHADOW } from "../theme";
import { useHomeScrollContext } from "../../contexts";

export default function Foundation() {
  const { foundationRef } = useHomeScrollContext();
  return (
    <Stack spacing={4} ref={foundationRef}>
      <Image
        sx={{ boxShadow: LIGHT_SHADOW, p: 2 }}
        variant="circle"
        src={LOGO_SQUARE}
        size="100px"
      />
      <Description />
    </Stack>
  );
}

function Description() {
  return (
    <Column spacing={2}>
      <Typography variant="h1">About Standard Labs</Typography>

      <Typography color="text.secondary">
        Standard Labs was founded in 2021 by a group of former founders and start up investors with the goal of providing a world class development experience catering to quick iteration and early stage idea budgets.
      </Typography>
      <Typography color="text.secondary">
        We've built products for over 20 companies across several verticals. We are comfortable with end-to-end app development, data processing and model training, and smart contract development and machine learning.
      </Typography>
      <Typography color="text.secondary">
        Our core values are efficiency, experimentation, and flexibility. We will be there to pivot and build with you no matter where your business takes you.
      </Typography>
    </Column>
  );
}
